<template>
    <div>
        <div class="head-container">
            <div>
                <div class="head-text-main">
                    WHAT IS NUTRISENSE
                </div>
            </div>
        </div>
        <v-container class="text-center">
            <v-row class="d-none">
                <v-col class="heading">
                    WHAT IS NUTRISENSE
                </v-col>
            </v-row>
        </v-container>

        <v-container class="main-text text-container">
            <v-row>
                <v-col>
                    <span class="green-text">NUTRISENSE</span> is a spinoff company of the Agricultural University of Athens aiming to support growers in applying economically viable and environment-friendly fertilization practices in horticultural crops. To achieve this goal, the company deploys a novel decision support system termed <span class="green-text">NutriSense</span> which includes two versions, one for soil-grown crops (<span class="green-text">SOIL NutriSense</span>) and a second one for soilless crops (<span class="green-text">SOILLESS NutriSense</span>).
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    The <span class="green-text">SOIL NutriSense</span> is a user-friendly decision support system (DSS) that has been created at the Agricultural University of Athens by integrating into a novel software cut-edge knowledge and technologies concerning crop fertigation. The software is based on a huge database originating mainly from credible international literature sources but also from experimental work on crop fertigation that has been conducted at AUA in the last 15 years. <span class="green-text">SOIL NutriSense</span> provides personalised advice about crop fertigation to the users after introduction of some input data, including the crop species, the season of the year, the mineral composition of the water and a soil analysis. Different types of soil analysis based on the most common protocols (e.g. extraction of water soluble or exchangeable nutrients at various soil to water mixing ratios) can be utilised by <span class="green-text">SOIL NutriSense</span>. Furthermore, rapid tests of the nutrient status in the root environment of the plants can be utilised to correct the fertigation scheme during the cropping period. The rapid tests involve measurements of the nutrient concentrations in samples of soil solution sucked directly from the soil.
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    The <span class="green-text">SOILLESS NutriSense</span> is a specialised DSS for the management of nutrition in soilless culture. This version of NutriSense utilises a series of sophisticated novel algorithms based on mass balance models to provide customised nutrient solution compositions for soilless cultivations in both open and closed-loop systems. The <span class="green-text">SOILLESS NutriSense</span> utilises standard recommendations originating from various literature sources concerning the nutrient requirements of a wide variety of vegetable and ornamental plant species, which are available in a database. When the <span class="green-text">SOILLESS NutriSense</span> is used to compute a nutrient solution for a particular crop, the recommendations included in the database are adapted to the specific characteristics and conditions of this crop. More specifically, the <span class="green-text">SOILLESS NutriSense</span> takes into consideration the following crop characteristics when a nutrient solution recipe is requested for a crop:
                    <ul>
                        <li>crop species,</li>
                        <li>season of the year,</li>
                        <li>plant developmental stage,</li>
                        <li>mineral composition of the raw water used to prepare the NS,</li>
                        <li>available fertilisers,</li>
                        <li>number and the volume of stock solution tanks</li>
                        <li>specific characteristics of the available equipment for fertigation.</li>
                    </ul>
                    Furthermore, the <span class="green-text">SOILLESS NutriSense</span> is used to regularly readjust the composition of the supplied nutrient solution during the cropping period, following a chemical analysis of a drainage solution sample.
                    <br><br>
                    <span class="green-text">NutriSense</span> is based on a concept aiming to provide a practical and effective solution for growers to efficiently manage their crops while reducing environmental impact and improving profitability.

                    <p style="font-size: 30px;color:#000;" class="mt-8 mb-10">
                        NutriSense is the future in fertigation management, and the future starts now!
                    </p>
                    <p>
                        Some of the practical advantages of <span class="green-text">NutriSense</span>:
                        <ul>
                            <li>Improves crop yield and product quality of horticultural crops by providing precise, customised nutrient solution compositions for fertigation.</li>
                            <li>Reduces costs for growers by providing nutrient solution formulae for fertigation tailored to the actual plant needs. </li>
                            <li>Reduces the environmental impact of fertilisation by optimising the supply of nitrogen and phosphorus, thus minimising pollution of water resources due to leaching and runoff.</li>
                            <li>Allows for the adaptation of the nutrient supply via fertigation to current crop needs taking into consideration various factors such as the crop species, the mineral composition of the water, the season of the year, and the plant developmental stage.</li>
                            <li>Provides a practical and effective tool to growers and advisors to manage the fertilization of their crops successfully and accurately. </li>
                            <li>Reduces environmental impact and improves profitability.</li>
                        </ul>
                        Ready to start?
                        <br>
                        <br>
                        <br>
                        <!-- If you are not a NUTRISENSE user yet, you can subscribe by clicking here: Subscribe now
                        <br>
                        If you are already a NUTRISENSE user, you can login by clicking here: Login here -->
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        }
    }
</script>
<style scoped>
.heading {
    color: #5FA936;
    font-size: 36px;
    font-weight: 700;
}

.head-container{
    background-image: url("~@/assets/about_a_head.png");
    height: 380px;
    width: 100%;
    z-index: 0;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 0px 20px #0304036b;
    margin-top: -50px;
}

.head-text-main {
    font-weight: 700;
    font-size: 96px;
}

.head-text-secondary {
    font-size: 32px;
}

.main-text {
    color: #646464;
    font-size: 16px;
    line-height: 26px;
}
.text-container {
    max-width: 65vw;
}

.green-text {
    color: #006600;
    font-weight: bold;
}
</style>
